import { useSession } from 'next-auth/react';
import {
	createContext,
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import { GET_CART_FOR_USER_ENDPOINT } from '../../pages/api/store/get-cart-for-user';
import { ExtendedCart } from '../../services/cart';

type CartContextType = {
	cart: ExtendedCart | undefined;
	setCart: Dispatch<SetStateAction<ExtendedCart | undefined>>;
};

export const CartContext = createContext({} as CartContextType);

type CartProviderProps = {
	children: JSX.Element;
};

const CartProvider = ({ children }: CartProviderProps) => {
	const [cart, setCart] = useState<ExtendedCart | undefined>();
	const { data: session } = useSession();

	useEffect(() => {
		const getCartForUser = async () => {
			if (session?.user?.id === undefined) {
				return undefined;
			}
			return await fetch(GET_CART_FOR_USER_ENDPOINT, {
				method: 'POST',
				body: JSON.stringify({ userId: session.user.id }),
			}).then((res) => res.json());
		};
		getCartForUser().then((res) => setCart(res));
	}, [session]);

	return (
		<CartContext.Provider
			value={{
				cart,
				setCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export default CartProvider;
