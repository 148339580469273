import { NextApiRequest, NextApiResponse } from 'next';
import { getCartForUser, ExtendedCart } from '../../../services/cart';

export const GET_CART_FOR_USER_ENDPOINT = '/api/store/get-cart-for-user';

type GetCartRequest = {
	userId: string;
};

export default async function handler(
	req: NextApiRequest,
	res: NextApiResponse<ExtendedCart>,
) {
	const { userId }: GetCartRequest = JSON.parse(req.body);
	const cart = await getCartForUser(userId);
	return res.status(200).json(cart);
}
