import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.min.css';

import type { AppProps } from 'next/app';
import { Analytics } from '@vercel/analytics/react';
import { SessionProvider } from 'next-auth/react';
import { NextUIProvider } from '@nextui-org/react';
import Script from 'next/script';
import CartProvider from '../components/layouts-and-providers/cart-provider';
import SelectedGenerationImageProvider from '../components/layouts-and-providers/selected-image-provider';
import { ToastContainer } from 'react-toastify';

const MyApp = ({
	Component,
	pageProps: { session, ...pageProps },
}: AppProps): JSX.Element => {
	return (
		<SessionProvider session={session}>
			{/* Google Analytics */}
			<Script
				id="ga-tag-script"
				strategy="lazyOnload"
				src={`https://www.googletagmanager.com/gtag/js?id="${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}"`}
			/>

			<Script strategy="lazyOnload" id="ga-data-script">
				{`
				  window.dataLayer = window.dataLayer || [];
				  function gtag(){dataLayer.push(arguments);}
				  gtag('js', new Date());
				
				  gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}");
				`}
			</Script>

			<SelectedGenerationImageProvider>
				<CartProvider>
					<NextUIProvider>
						<Component {...pageProps} />
						<Analytics />
						<ToastContainer />
					</NextUIProvider>
				</CartProvider>
			</SelectedGenerationImageProvider>
		</SessionProvider>
	);
};

export default MyApp;
