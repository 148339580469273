import { ImageInfo } from './types/images';

export const DEFAULT_IMAGE_GENERATION_URL =
	'https://res.cloudinary.com/de8sb8oyd/image/upload/v1676911686/site_assets/placeholder_inference_image_wmnwhy.png';

export const DEFAULT_IMAGE_GENERATION: ImageInfo = {
	url: DEFAULT_IMAGE_GENERATION_URL,
	id: '',
	width: 512,
	height: 512,
	prompt: 'painting of the image fountain robot',
};

export const IMAGE_FOUNTAIN_CONTACT_EMAIL = 'contact@imagefountain.com';
export const IMAGE_FOUNTAIN_SUPPORT_EMAIL = 'support@imagefountain.com';
