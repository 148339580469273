import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { DEFAULT_IMAGE_GENERATION } from '../../constants';
import { ImageInfo } from '../../types/images';

type SelectedGenerationImageContextType = {
	selectedGenerationImage: ImageInfo;
	setSelectedGenerationImage: Dispatch<SetStateAction<ImageInfo>>;
};

export const SelectedGenerationImageContext = createContext(
	{} as SelectedGenerationImageContextType,
);

type SelectedGenerationImageProviderProps = {
	children: JSX.Element | Array<JSX.Element | undefined>;
};

const SelectedGenerationImageProvider = ({
	children,
}: SelectedGenerationImageProviderProps) => {
	const [selectedGenerationImage, setSelectedGenerationImage] =
		useState<ImageInfo>(DEFAULT_IMAGE_GENERATION);

	return (
		<SelectedGenerationImageContext.Provider
			value={{
				selectedGenerationImage,
				setSelectedGenerationImage,
			}}
		>
			{children}
		</SelectedGenerationImageContext.Provider>
	);
};

export default SelectedGenerationImageProvider;
