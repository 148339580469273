import { prisma } from '../prisma/shared-client';
import { Cart, CartItem } from '@prisma/client';
import { ExtendedProductVariant } from './products';

export type ExtendedCartItem = CartItem & {
	productVariant: ExtendedProductVariant;
};

export type ExtendedCart = Cart & {
	cartItems: Array<ExtendedCartItem>;
};

const cartIncludeStub = {
	cartItems: {
		include: {
			productVariant: {
				include: {
					product: true,
					color: true,
				},
			},
		},
	},
};

export const getCartForUser = async (userId: string): Promise<ExtendedCart> => {
	const cart = await prisma.cart.findUnique({
		where: {
			userId: userId,
		},
		include: cartIncludeStub,
	});
	if (cart === null || cart === undefined) {
		const newCart = await prisma.cart.create({
			data: {
				userId: userId,
			},
			include: cartIncludeStub,
		});
		return newCart;
	}
	return cart;
};

export type ImageFountainStoreCartItem = {
	quantity: number;
	imageUrl?: string;
	productVariantId: string;
};

export const addItemToCart = async (
	cartId: string,
	cartItem: ImageFountainStoreCartItem,
): Promise<ExtendedCart> => {
	const newItem = await prisma.cartItem.create({
		data: {
			quantity: cartItem.quantity,
			imageUrl: cartItem.imageUrl,
			productVariant: {
				connect: {
					id: cartItem.productVariantId,
				},
			},
			cart: {
				connect: {
					id: cartId,
				},
			},
		},
		select: {
			cart: {
				include: cartIncludeStub,
			},
		},
	});
	return newItem.cart;
};

export const deleteItemFromCart = async (
	cartItemId: string,
): Promise<ExtendedCart> => {
	const deletedItem = await prisma.cartItem.delete({
		where: {
			id: cartItemId,
		},
		select: {
			cart: {
				select: {
					id: true,
				},
			},
		},
	});
	const cart = await prisma.cart.findFirstOrThrow({
		where: {
			id: deletedItem.cart.id,
		},
		include: cartIncludeStub,
	});
	return cart;
};

export const clearCart = async (cartId: string): Promise<void> => {
	await prisma.cartItem.deleteMany({
		where: {
			cartId: cartId,
		},
	});
};
